
import colorPicker from '@caohenghu/vue-colorpicker'
import { isNil } from 'lodash'
import UIDMixin from '@/dashboard/mixins/uid-mixin'
import { RGBToHex } from '@/utils/helpers'
/**
 * Base style for a color picker input. Compatible with v-model.
 *
 */
export default {
  name: 'ColorPickerPeppermint',
  components: { colorPicker },
  mixins: [UIDMixin],
  props: {
    /**
     * Default color for the picker
     *
     * Default: `White`
     */
    value: {
      type: String,
      default: '#FFFFFF'
    },
    /**
     * Validation state of input
     */
    state: {
      type: null,
      default: null
    },
    /**
     * Size for the input fields
     */
    size: {
      type: String,
      default: null
    },
    /**
     * Whether the input should be readonly
     */
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showColorPicker: false,
      color: null
    }
  },
  computed: {
    inputState() {
      if (!isNil(this.state)) {
        return this.state ? 'is-valid' : 'is-invalid'
      }

      return null
    },
    activatorState() {
      if (!isNil(this.state)) {
        return this.state ? 'border-success' : 'border-danger'
      }

      return null
    }
  },
  watch: {
    value() {
      this.color = this.value
    },

    color() {
      /**
       * input event.
       *
       * @type {string}
       */
      this.$emit('input', this.color)
      this.$emit('change', this.color)
    }
  },
  created() {
    // Set default color on nextTick
    // This prevents an error for Vee-Validate
    this.$nextTick(() => {
      this.color = this.value || undefined
    })
  },
  methods: {
    changeColor({ rgba }) {
      if (this.readonly) {
        return
      }
      const { r, g, b } = rgba
      const rgbColor = `rgb(${ r }, ${ g }, ${ b })`
      this.color = RGBToHex(rgbColor)
    }
  }
}
