
import { mapGetters } from 'vuex'

/**
 * Account specific menu dropdown selector. This input field
 * allows the user to select a created menu as a field value.
 */
export default {
  name: 'MenuSelector',
  props: {
    /**
     * Placeholder text for select dropdown
     */
    placeholder: {
      type: String,
      default: 'Select a menu'
    },
    /**
     * Value is needed to handle v-model two way binding. Default value
     * is null to auto-select the dropdown placeholder option.
     */
    value: {
      required: false,
      type: null,
      default: null
    }
  },
  data() {
    return {
      innerValue: null
    }
  },
  computed: {
    ...mapGetters({
      // The options will be a list of an Account's menus
      // pulled from the database.
      menus: 'website/getMenus'
    })
  },
  watch: {
    /**
     * Handles internal model changes.
     */
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    /**
     * Handles external model changes.
     */
    value(newVal) {
      this.innerValue = newVal
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }

    // Call database for menus
  },
  methods: {
    async initMenuOptions() {}
  }
}
