import { render, staticRenderFns } from "./DfbFormArray.vue?vue&type=template&id=5a97a673&scoped=true"
import script from "./DfbFormArray.vue?vue&type=script&lang=js"
export * from "./DfbFormArray.vue?vue&type=script&lang=js"
import style0 from "./DfbFormArray.vue?vue&type=style&index=0&id=5a97a673&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a97a673",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DfbFormArray: require('/dashboard/forms/dynamic-form-array/DfbFormArray.vue').default,DfbFormGroup: require('/dashboard/forms/dynamic-form-group/DfbFormGroup.vue').default})
