
import * as _ from "lodash"
import draggable from "vuedraggable"
import DfbFormGroup from "@/dashboard/forms/dynamic-form-group"
import UIDMixin from "@/dashboard/mixins/uid-mixin"
import Titlecase from "@/dashboard/filters/titlecase"

/**
 * The DfbFormArray handles if we need to inject a form array
 * to a dynamic form.
 */
export default {
  name: "DfbFormArray",
  components: { DfbFormGroup, draggable },
  filters: { Titlecase },
  /**
   * Give each DfbFormGroup a unique ID
   */
  mixins: [UIDMixin],
  props: {
    /**
     * The form group model for the form. This is mainly
     * used for nested form group structures.
     *
     * We can now maintain and pass nested form models
     * down from the parent group to the child fields.
     */
    formGroup: {
      type: null,
      default: null
    },
    /**
     * The form field is the individual or nested form
     * field object. A typical object looks like:
     *
     * {
     *  type: { String } // 'input',
     *  name: { String } // "last_name",
     *  label: { String } // "Last Name",
     *  className: { String } // "col-6",
     *  validation: { String, Object } //"required|alpha_num",
     * }
     *
     * Additional properties can be set in a templateOptions object:
     * {
     *  ... Default form field settings
     * templateOptions: {
     *     className: { String, Object } // "bg-light",
     *    label: { String } // "Autopay",
     *    description: { String } // "Sign up for Auto Pay and your rent will be automatically drafted from your account each month."
     *  }
     * }
     */
    formField: {
      type: Object,
      default() {
        return {}
      }
    },
    /**
     * Whether to show the heading for a group of form array items. We
     * typically use this only in the website editor sidebar
     */
    showHeading: {
      type: Boolean,
      default: false
    },
    /**
     * Whether this form array has collapse
     */
    isCollapsible: {
      type: Boolean,
      default: false
    },
    /**
     * Whether form group is disabled
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Must be included in props for two-way binding for v-model
     */
    value: {
      type: null,
      default: null
    },
    /** make text small */
    isEditorLabelClass: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      innerFormGroup: null,
      innerValue: null,
      isLoaded: false
    }
  },
  computed: {
    /**
     * Check whether the limit on repeaters have been hit.
     * 
     * If the limit is <= 0, then we allow unlimited repeater content. If it's > 0,
     * we limit the number of repeater items to the limit.
     */
    limitHit() {
      if (this.formField && this.formField.schema && this.formField.schema.type === 'repeater' && this.formField.schema.limit > 0 && this.innerFormGroup.length >= this.formField.schema.limit) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    /**
     * Handles internal model changes.
     */
    innerValue(newVal) {
      this.$emit("input", newVal)
    },
    /**
     * Handles external model changes.
     */
    value: {
      handler(newVal) {
        this.innerValue = newVal
      },
      deep: true,
      immediate: true
    },
    /**
     * Handles internal form group model changes.
     */
    innerFormGroup(newVal) {
      this.$emit("formGroupChange", newVal)
    },
    /**
     * Handles external model changes.
     */
    formGroup: {
      handler(newVal) {
        this.innerFormGroup = newVal
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    /**
     * Check whether form group is of repeater type
     */
    isRepeater(field) {
      return field.type === "repeater"
    },
    onAddFormRow(event) {
      // Create empty form row object
      let fieldsObj = {}
      if (this.innerValue && this.innerValue.length) {
        let clonedObj = _.cloneDeep(this.innerValue[0])
        clonedObj = this.skeleton(clonedObj)
        fieldsObj = clonedObj
      }

      // Add nested children fieldsObj
      if (this.formField.children && this.formField.children.length) {
        for (let child of this.formField.children) {
          if (child.children && child.children.length) {
            fieldsObj[child.schema.prop_variable_name] = []
          }
        }
      }

      // Add form row to inner form group
      if (typeof this.innerFormGroup === "undefined" || this.innerFormGroup === null) {
        this.innerFormGroup = []
      }

      this.innerFormGroup.push(fieldsObj)
    },
    onRemoveFormRow(index) {
      this.innerFormGroup.splice(index, 1)
    },
    getName(formField, index) {
      // console.log("🚀 ~ file: DfbFormArray.vue ~ line 335 ~ getName ~ index", index, this.value)
      /**
       * <span v-if="formField.label || formField.name">{{ formField.label || formField.name }} item</span>
              <span v-else-if="formField.schema && formField.schema.prop_variable_name">{{ formField.schema.prop_variable_name | titlecase }} item</span>
       */
      let itm = _.find(formField.children, (o) => { return o.schema.label.toLowerCase() == 'title' || o.schema.label.toLowerCase() == 'label' })
      if (itm && itm.schema && this.value[index] && this.value[index][itm.schema.prop_variable_name]) {
        return this.value[index][itm.schema.prop_variable_name]
      }
      else if (formField.label || formField.name) {
        return formField.label || formField.name + " item"
      }
      else if (formField.schema && formField.schema.prop_variable_name) {
        return formField.schema.prop_variable_name + " item"
      }
    },
    /**
     * This function goes through an object's properties and
     * recursively creates an empty default value.
     *
     * Source found here: https://stackoverflow.com/a/24663893
     */
    skeleton(source, isArray) {
      const o = Array.isArray(source) ? [] : {}
      for (const key in source) {
        if (source.hasOwnProperty(key) && !_.isNil(source[key])) {
          const t = typeof source[key]
          o[key] = t === "object" ? this.skeleton(source[key]) : { string: "", number: 0, boolean: false }[t]
        } else {
          o[key] = ""
        }
      }
      return o
    }
  }
}
