
import { ValidationProvider } from 'vee-validate'
import UIDMixin from '@/dashboard/mixins/uid-mixin'

// Anytime we add a custom field input we add it to the formField mapper.
// This helps with the Vee-Validate issue documented below
import ColorPickerPeppermint from '@/dashboard/forms/color-picker-peppermint'
import FormInputSwitch from '@/dashboard/forms/form-input-switch'
import BrowseImagesMozzarella from '@/dashboard/forms/browse-images-mozzarella'
import RichTextEditor from '@/dashboard/forms/rich-text-editor'
import MenuSelector from '@/dashboard/forms/menu-selector'

const customFormFieldMapper = {
  'b-form-color': ColorPickerPeppermint,
  'b-form-color-picker': ColorPickerPeppermint,
  'b-form-switch': FormInputSwitch,
  'b-form-image': BrowseImagesMozzarella,
  'b-form-image-picker': BrowseImagesMozzarella,
  'b-form-rich-text': RichTextEditor,
  'b-form-editor': RichTextEditor,
  'b-form-menu-selector': MenuSelector,
  'b-form-menu-picker': MenuSelector
}
/**
 * The DfbFormGroup handles each individual form field
 * inside the Dynamic Form Builder Form.
 *

 * If the form field has a child fields array, we recursively
 * build the DfbFormGroup to allow for nested form structures.
 */
export default {
  name: 'DfbFormGroup',
  components: {
    ValidationProvider,
    ...customFormFieldMapper
  },
  /**
   * Give each DfbFormGroup a unique ID
   */
  mixins: [UIDMixin],
  props: {
    /**
     * The form group model for the form. This is mainly
     * used for nested form group structures.
     *
     * We can now maintain and pass nested form models
     * down from the parent group to the child fields.
     */
    formGroup: {
      type: null,
      default: null
    },
    /**
     * The form field is the individual or nested form
     * field object. A typical object looks like:
     *
     * {
     *  type: { String } // 'input',
     *  name: { String } // "last_name",
     *  label: { String } // "Last Name",
     *  className: { String } // "col-6",
     *  validation: { String, Object } //"required|alpha_num",
     * }
     *
     * Additional properties can be set in a templateOptions object:
     * {
     *  ... Default form field settings
     * templateOptions: {
     *     className: { String, Object } // "bg-light",
     *    label: { String } // "Autopay",
     *    description: { String } // "Sign up for Auto Pay and your rent will be automatically drafted from your account each month."
     *  }
     * }
     */
    formField: {
      type: Object,
      default() {
        return {}
      }
    },
    /**
     * Whether form group is inline
     */
    inline: {
      type: Boolean,
      default: false
    },
    /**
     * Whether form group is disabled
     */
    disabled: {
      type: Boolean,
      default: false
    },
    // must be included in props
    value: {
      type: null,
      default: null
    },
    /** make text small */
    isEditorLabelClass: {
      type: Boolean,
      default: true
    },
    /**
     * Whether image pickers should have filters
     */
    hasFilters: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    innerValue: null,
    isLoaded: false
  }),
  computed: {
    isInput() {
      if (this.formField && this.formField.type) {
        return !this.formField.type.includes('rich-text') && !this.formField.type.includes('textarea') && (this.formField.type.includes('text') || this.formField.type.includes('password') || this.formField.type.includes('url') || this.formField.type.includes('email') || this.formField.type.includes('tel') || this.formField.type.includes('range'))
      } else {
        return false
      }
    },

  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    // Handles external model changes.
    value: {
      handler(newVal) {
        this.innerValue = newVal
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    /**
     * Because of custom inputs changing Vue render state on load,
     * Vee-Validate runs validation immediately. We need to reset
     * the validator on load -- unless it's specified to validate
     * on load.
     *
     * Resetting single fields can be found in these issues:
     *  - https://github.com/baianat/vee-validate/issues/1031
     *  - https://github.com/baianat/vee-validate/issues/1770
     * Vue change detection caveats can be found here: https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
     *
     * P.S. All this for a nice user experience? ¯\_(ツ)_/¯
     */
    setTimeout(() => {
      const customField = customFormFieldMapper[`b-form-${ this.formField.type }`]
      // We know if a custom input exists because inputType is false
      if (!this.formField.validateOnLoad && this.$refs.validator && customField) {
        this.$refs.validator.reset()
      }
      // We add this hacky isLoaded flag for formGroup because
      // of vee-validates re-rendering issues linked above
      // *NOTE* This must come after form validator has been reset
      this.isLoaded = true
    }, 0)
  },
  methods: {
    formFieldName() {
      let label = this.formField.templateOptions && this.formField.templateOptions.label ? this.formField.templateOptions.label : this.formField.label || this.formField.name
      if (this.formField.is_required) {
        label = label + " *"
      }
      return label
    }
  }
}
