
import { BFormCheckbox } from 'bootstrap-vue'
/**
 * Default custom switch wrapped around BootstrapVue's checkbox.
 *
 */
export default {
  name: 'FormInputSwitch',
  components: {
    BFormCheckbox
  },
  props: {
    /**
     * The name of the input
     */
    name: {
      type: String,
      default: ''
    },
    /**
     * The size of the input
     */
    size: {
      type: String,
      default: 'md'
    },
    /**
     * Value is needed to handle v-model two way binding. Default value
     * is null to auto-select the dropdown placeholder option.
     */
    value: {
      required: false,
      type: null,
      default: null
    }
  },
  data() {
    return {
      innerValue: null
    }
  },
  watch: {
    /**
     * Handles internal model changes.
     */
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    /**
     * Handles external model changes.
     */
    value(newVal) {
      this.innerValue = newVal
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
