
import _ from "lodash"
import { mapGetters, mapActions } from "vuex"
import BasePopoverLollipop from "@/dashboard/website-editor/base-popover-lollipop"
import PugImg from "@/dashboard/pug-img"
import ImageFilterDropdown from "@/dashboard/forms/image-filter-dropdown"

export default {
  name: "BrowseImagesMozzarella",
  components: {
    BasePopoverLollipop,
    PugImg,
    ImageFilterDropdown
  },
  props: {
    /**
     * Add a default value.
     */
    defaultValue: {
      type: Object,
      default: null
    },
    /**
     * Toggle off the ability to show the image input field.
     */
    showImageInput: {
      type: Boolean,
      default: true
    },
    /**
     * Toggle off the ability to show the image preview.
     */
    showImagePreview: {
      type: Boolean,
      default: true
    },
    /**
     * If this is selected the input value gets cleared.
     * This is useful when selecting multiple images: https://invis.io/6PSC8WER87E#/372901026_Admin_UI_StorageManager_Locations_Add
     */
    clearInputOnselect: {
      type: Boolean,
      default: false
    },
    /**
     * Will stay open after selecting a single item
     */
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    /**
     * Use this to only show icons
     */
    showOnlyIcons: {
      type: Boolean,
      default: false
    },
    /**
     * Place holder text for input
     */
    placeholderText: {
      type: String,
      default: null
    },
    /**
     * Size for the input fields
     */
    size: {
      type: String,
      default: null
    },
    /**
     * Value is needed to handle v-model two way binding. Default value
     * is null to auto-select the dropdown placeholder option.
     */
    value: {
      required: false,
      type: null,
      default: null
    },
    /**
     * Whether to add filters to image picker
     */
    hasFilters: {
      type: Boolean,
      default: false
    },
    /**
     * Number of columns for diplsaying items in
     */
    cols: {
      type: Number,
      default: 4
    },
    /**
     * The size of the popover: sm or md
     */
    popoverSize: {
      type: String,
      default: "md"
    },
    popoverPlacement: {
      type: String,
      default: "top"
    }
  },
  data() {
    return {
      image: {
        id: "",
        src: "",
        title: "",
        alt: "",
        tags: [],
        is_icon: false,
        is_global: false,
        type: ""
      },
      mainProps: {
        blank: true,
        blankColor: "#E3E3E3",
        width: 46,
        height: 46,
        class: ""
      },
      filteredImages: [],
      innerValue: null
    }
  },
  computed: {
    ...mapGetters({
      images: "media/getImages",
      viewImagesType: "media/getViewImagesType",
      stockPhotos: "media/getStockPhotos",
      globalMedia: "media/getGlobalMedia",
      icons: "media/getIcons",
      iconsCount: "media/getIconsCount"
    }),
    browseButtonId() {
      return `browse-button-${ Math.floor(Math.random() * 1000000) }`
    }
  },
  watch: {
    images: {
      handler(val) {
        this.setFilteredImages()
      },
      deep: true
    },
    globalMedia: {
      handler(val) {
        this.setFilteredImages()
      },
      deep: true
    },
    image: {
      handler(val) {
        if (!val) {
          return
        }

        if (val.src) {
          this.mainProps.blank = false
        } else {
          this.mainProps.blank = true
        }
        /**
         * event triggered when a change happens
         *
         * @type { object }
         */
        this.$emit("onChange", val)
      },
      deep: true
    },
    /**
     * Watch any default value changes for vue detection
     */
    defaultValue: {
      handler(val) {
        if (!val) {
          return
        }
        this.image = this.innerValue
      },
      deep: true
    },
    /**
     * Handles internal model changes.
     */
    innerValue(newVal) {
      this.$emit("input", newVal)
    },
    viewImagesType(newVal) {
      this.setFilteredImages()
    },
    /**
     * Handles external model changes.
     */
    value: {
      handler(newVal) {
        this.image = this.innerValue = newVal
      },
      deep: true
    }
  },
  created() {
    if (this.defaultValue) {
      this.image = this.innerValue = this.defaultValue
    } else if (this.value) {
      this.image = this.innerValue = this.value
    } else {
      this.image = {}
    }
    this.setFilteredImages()
  },
  methods: {
    ...mapActions({
      setGlobalMedia: "media/setGlobalMedia"
    }),
    setFilteredImages() {
      if (this.viewImagesType === null) {
        this.filteredImages = _.cloneDeep(this.images)
      } else if (this.viewImagesType.value === "icons" || this.showOnlyIcons === true) {
        this.filteredImages = _.cloneDeep(this.icons)
      } else if (this.viewImagesType.value === "stock") {
        this.filteredImages = _.cloneDeep(this.stockPhotos)
      } else if (this.viewImagesType.value === "global") {
        this.filteredImages = _.cloneDeep(this.globalMedia)
      } else if (this.showOnlyIcons === false) {
        this.filteredImages = _.cloneDeep(this.images)
      }
    },
    onSelected(image) {
      this.image = this.innerValue = image
      /**
       * event triggered when an image is selected
       *
       * @type { object }
       */
      this.$emit("onSelected", image)
      if (this.clearInputOnselect === true) {
        this.image = this.innerValue = {}
      }
    },
    /**
     * Remove selected image
     */
    removeImage(event) {
      /**
       * Event triggered when an image is removed
       *
       * @type { object }
       */
      this.$emit("removed", this.image)

      this.image = this.innerValue = {}
    }
  }
}
