import { render, staticRenderFns } from "./ColorPickerPeppermint.vue?vue&type=template&id=6e5be599&scoped=true&lang=pug"
import script from "./ColorPickerPeppermint.vue?vue&type=script&lang=js"
export * from "./ColorPickerPeppermint.vue?vue&type=script&lang=js"
import style0 from "./ColorPickerPeppermint.vue?vue&type=style&index=0&id=6e5be599&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e5be599",
  null
  
)

export default component.exports